<template>
  <section class="section">
    <h2 class="title">
      {{ $t('route.dataInputs') }}
      <div class="level">
        <div class="level-left subtitle">
          {{ $t('subtitle.dataInputs') }}
        </div>
        <div class="level-right">
          <button class="button is-primary" @click.prevent="create">
            <octicon :icon="plus" /><span>{{ $t('newDataInput') }}</span>
          </button>
        </div>
      </div>
    </h2>
    <box>
      <data-table
        ref="table" :items="items" sort-by="_id"
        :theme="$_ui_theme_tables"
      >
        <data-column field="source_rename" :label="$t('name')" width="15%" />
        <data-column field="description" :label="$t('description')" />
        <data-column field="enabled" :label="$t('status')" width="15%">
          <template slot-scope="{ value, item: input }">
            <span v-if="value" class="tag is-success is-light">{{ $t('enabled') }}</span>
            <span v-else class="tag is-warning is-light">{{ $t('disabled') }}</span>

            <span v-if="input.protocol === 'tcp' && input.ssl || input.protocol === 'http'" class="tag is-success is-light">SSL</span>
          </template>
        </data-column>
        <data-column field="source_type" :label="$t('sourceType')" width="15%">
          <template slot-scope="props">
            <a v-if="sourceTypeNames[props.value]" href="#" @click.prevent>{{ sourceTypeNames[props.value].name }}</a>
          </template>
        </data-column>
        <data-column field="index" :label="$t('index')" width="10%">
          <template slot-scope="props">
            <a v-if="indexNames[props.value]" href="#" @click.prevent>{{ indexNames[props.value].name }}</a>
          </template>
        </data-column>
        <data-column field="protocol" :label="$t('protocol')" width="10%">
          <template slot-scope="{ value, item: input }">
            <span v-if="input.protocol === 'http'" class="tag is-uppercase is-warning is-light">{{ value }}</span>
            <div v-else class="is-uppercase">
              <span v-if="input.protocol === 'tcp'" class="tag is-success is-light">{{ value }}</span>
              <span v-if="input.protocol === 'udp'" class="tag is-info is-light">{{ value }}</span>
              <span class="tag is-primary is-light">{{ input.port }}</span>
            </div>
          </template>
        </data-column>
        <data-column
          :label="$t('actions')" class="has-text-centered" :sortable="false"
          width="15%"
        >
          <template slot-scope="props">
            <div class="has-text-centered">
              <button class="button is-text" @click.prevent="edit(props.item)">
                <octicon :icon="pencil" /> <span>{{ $t('edit') }}</span>
              </button>
              <button class="button is-text" @click.prevent="destroy(props.item)">
                <octicon :icon="x" /> <span>{{ $t('delete') }}</span>
              </button>
            </div>
          </template>
        </data-column>
      </data-table>
    </box>
    <modal v-if="item" :show.sync="modal">
      <box>
        <div slot="header">
          {{ item.source_rename || $t('newDataInput') }}
        </div>

        <form @submit.prevent="submit">
          <div class="field">
            <label class="label">{{ $t('name') }}</label>
            <div class="control">
              <input
                v-model="item.source_rename" class="input" type="text"
                required
              >
            </div>
          </div>

          <div class="field">
            <label class="label">{{ $t('description') }}</label>
            <div class="control">
              <input v-model="item.description" class="input" type="text">
            </div>
          </div>

          <label class="label">{{ $t('protocol') }}</label>
          <div class="field has-addons">
            <p class="control">
              <span class="select">
                <select v-model="item.protocol" required>
                  <option disabled value="">{{ $t('selectDefault') }}</option>
                  <option v-for="protocol in protocols" :key="protocol" :value="protocol">{{ protocol.toUpperCase() }}</option>
                </select>
              </span>
            </p>

            <p v-if="item.protocol === 'tcp' || item.protocol === 'udp'" class="control is-expanded">
              <input
                v-model="item.port" class="input" type="number"
                :placeholder="$t('port')" required
              >
            </p>
            <template v-if="item.protocol === 'http'">
              <p class="control is-expanded">
                <input
                  v-model="item.token" class="input" type="text"
                  :placeholder="$t('token')"
                >
              </p>
              <div class="control">
                <button type="button" class="button" @click.prevent="randomHTTPToken">
                  <octicon :icon="sync" /> <span>{{ $t('random') }}</span>
                </button>
              </div>
              <div class="control">
                <button type="button" class="button" @click.prevent="copyHTTPToken">
                  <octicon :icon="clippy" /> <span>{{ $t('copy') }}</span>
                </button>
              </div>
            </template>
          </div>

          <div v-if="item.protocol !== 'http'" class="field">
            <label class="label">SSL</label>
            <input
              id="ssl" v-model="item.ssl" type="checkbox"
              name="ssl" class="switch"
            >
            <label for="ssl">{{ item.ssl ? $t('enabled') : $t('disabled') }}</label>
          </div>

          <div v-if="item.protocol !== 'http'" class="field" @keydown.enter.prevent>
            <label class="label">{{ $t('accessIPs') }}</label>
            <v-select
              v-model="item.access_ips" multiple taggable
              no-drop
            />
          </div>

          <div class="columns">
            <div class="column">
              <label class="label">{{ $t('index') }}</label>
              <div class="field">
                <p class="control">
                  <span class="select is-fullwidth">
                    <select v-model="item.index" required>
                      <option disabled value="">{{ $t('selectDefault') }}</option>
                      <option v-for="e in indexNames" :key="e.id" :value="e.id">{{ e.name }}</option>
                    </select>
                  </span>
                </p>
              </div>
            </div>
            <div class="column">
              <label class="label">{{ $t('sourceType') }}</label>
              <div class="field">
                <p class="control">
                  <span class="select is-fullwidth">
                    <select v-model="item.source_type" required>
                      <option disabled value="">{{ $t('selectDefault') }}</option>
                      <option v-for="e in sourceTypeNames" :key="e.id" :value="e.id">{{ e.name }}</option>
                    </select>
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">{{ $t('status') }}</label>
            <input
              id="enabled" v-model="item.enabled" type="checkbox"
              name="enabled" class="switch"
            >
            <label for="enabled">{{ item.enabled ? $t('enabled') : $t('disabled') }}</label>
          </div>

          <div slot="footer" class="field is-grouped is-grouped-right">
            <div class="control">
              <button type="submit" class="button is-link">
                {{ $t('save') }}
              </button>
            </div>
            <div class="control">
              <button type="button" class="button is-link is-light" @click.prevent="closeModal">
                {{ $t('cancel') }}
              </button>
            </div>
          </div>
        </form>
      </box>
    </modal>
  </section>
</template>
<script>
import { DataTable, DataColumn, Modal } from '@cyradar/ui'
import { plus, x, pencil, sync, clippy } from 'octicons-vue'
import { v4 as uuid } from 'uuid'
import clipboard from '@/clipboard.js'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

const protocols = ['http', 'tcp', 'udp']
const nameMap = (names) => names.reduce((obj, item) => {
  obj[item.id] = item
  return obj
}, {})

export default {
  components: { DataTable, DataColumn, Modal, vSelect },
  data () {
    return {
      modal: false,
      item: null,
      source_types: [],
      indexes: []
    }
  },
  computed: {
    plus () {
      return plus
    },
    x () {
      return x
    },
    pencil () {
      return pencil
    },
    sync () {
      return sync
    },
    clippy () {
      return clippy
    },
    protocols () {
      return protocols
    },
    sourceTypeNames () {
      return nameMap(this.source_types)
    },
    indexNames () {
      return nameMap(this.indexes)
    }
  },
  watch: {
    'item.protocol' (val) {
      if (!val || (this.item && this.item.token)) {
        return
      }

      this.randomHTTPToken()
    }
  },
  mounted () {
    this.loadSourceTypes()
    this.loadIndexes()
  },
  methods: {
    copyHTTPToken () {
      clipboard(this.item.token || '')
    },
    randomHTTPToken () {
      const token = uuid()
      if (!token) {
        return
      }

      this.$set(this.item, 'token', token.replace(/-/g, ''))
    },
    loadSourceTypes () {
      return this.$http.get('/api/v1/source-types/names').then(body => {
        if (!body || !body.data || !body.data.data) {
          return
        }

        this.source_types = body.data.data
      })
    },
    loadIndexes () {
      return this.$http.get('/api/v1/indexes/names').then(body => {
        if (!body || !body.data || !body.data.data) {
          return
        }

        this.indexes = body.data.data
      })
    },
    closeModal () {
      this.modal = false
    },
    submit () {
      if (this.item.id) {
        this.update()
        return
      }

      this.store()
    },
    create () {
      this.item = {
        protocol: '',
        source_type: '',
        index: '',
        enabled: true
      }

      this.modal = true
    },
    edit (item) {
      this.item = JSON.parse(JSON.stringify(item))
      this.modal = true
    },
    items (filter, order, pagination) {
      return this.$http.get('/api/v1/data-inputs', {
        params: {
          filter: filter.query,
          sort: order.by,
          order: order.order,
          page: pagination.page,
          limit: pagination.perPage
        }
      }).then(body => {
        if (!body || !body.data) {
          return {}
        }

        return body.data.data
      })
    },
    store () {
      this.item.port = parseInt(this.item.port)
      return this.$http.post('/api/v1/data-inputs', this.item).then(body => {
        if (!body || !body.data) {
          return
        }

        this.closeModal()
        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.$refs.table.loadItems()
      })
    },
    update () {
      this.item.port = parseInt(this.item.port)
      return this.$http.patch(`/api/v1/data-inputs/${this.item.id}`, this.item).then(body => {
        if (!body || !body.data) {
          return
        }

        this.closeModal()
        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.$refs.table.loadItems()
      })
    },
    destroy (item) {
      if (!window.confirm(this.$t('rus'))) {
        return
      }

      return this.$http.delete(`/api/v1/data-inputs/${item.id}`).then(body => {
        if (!body || !body.data) {
          return
        }

        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.$refs.table.loadItems()
      })
    }
  }
}
</script>
